import React from "react";
import Helmet from 'react-helmet'
import Layout from "../components/layout";
import Logo from "../_images/campaign-logo.svg";
import DOD from '../components/dods';

var pageTitle = 'MacKenzie Scott-Dan Jewett $40 Million Gift: FAQs';

var pageDesc = 'In June 2021, Cal State Fullerton was awarded an unrestricted $40 million gift from philanthropist MacKenzie Scott and her husband, Dan Jewett.';

export default (props) => (
  <React.Fragment>
  <Layout locationInfo={props.location}>
    <Helmet>
      <title>{pageTitle}</title>
      <body className="board" />
      <meta itemprop="name" content={pageTitle} />
      <meta name="twitter:title" content={pageTitle} />
      <meta property="og:title" content={pageTitle} />
      <meta name="description" content={pageDesc} />
      <meta itemprop="description" content={pageDesc} />
      <meta name="twitter:description" content={pageDesc} />
      <meta property="og:description" content={pageDesc} />
    </Helmet>
    <section style={{marginTop: '50px', backgroundColor: '#EFEDE6'}}>
      <article className="fixed">
        <h1>MacKenzie Scott-Dan Jewett $40 Million Gift: FAQs</h1>
        <p>In June 2021, Cal State Fullerton was <a href="http://news.fullerton.edu/2021/06/philanthropist-mackenzie-scott-gives-40-million-gift-to-cal-state-fullerton/" target="_blank" rel="noopener noreferrer">awarded an unrestricted $40 million gift</a> from philanthropist MacKenzie Scott and her husband, Dan Jewett.</p>
      </article>
    </section>
    <section style={{backgroundColor: '#ffffff'}}>
      <article className="fixed">
        
      <h2>How is the Scott-Jewett gift being allocated?</h2>
      <ul>
        <li><strong>$15 million</strong> — To create the MacKenzie Scott & Dan Jewett Endowed Fund for Excellence<br /><br />This endowment will provide annual funding that allows campus leadership to take advantage of opportunities that support the mission of CSUF.</li>
        <li><strong>$5 million</strong> — To create the MacKenzie Scott & Dan Jewett President’s Initiative Endowed Fund<br /><br />This endowment will provide funding for unique opportunities and initiatives that support the Titan community.</li>
        <li><strong>$2 million</strong> — To grow diversity, equity and inclusion within the campus community<br /><br />This $1 million endowment and $1 million in current-use funds will allow for immediate impact and long-term sustainability in diversity, equity and inclusion work.</li>
        <li><strong>$2 million</strong> — To further develop the academic offerings at CSUF<br /><br />This $1 million endowment and $1 million in current-use funds will allow for immediate impact and long-term sustainability in further developing the academic offerings, including enhancing faculty-mentored undergraduate research, and recruiting and retaining diverse faculty.</li>
        <li><strong>$2 million</strong> — To enhance the Titan student experience at CSUF<br /><br />This $1 million endowment and $1 million in current-use funds will allow for immediate impact and long-term sustainability in enhancing the Titan student experience.</li>
        <li><strong>$3 million</strong> — To recognize and fund extraordinary activities and programs in the CSUF community<br /><br />A committee of campus stakeholders will be asked to manage an inclusive and transparent RFP process where members of the campus community can apply for funds. The goal is to fund new, innovative ideas or expand existing programs that enhance the Titan experience at CSUF. Details on the RFP process are under development and will be shared widely with the campus community once completed.</li>
        <li><strong>$11 million</strong> — To engage and motivate donors to support all areas of the campus community through a matching gift program<br /><br />The funding will provide a 50% match for endowment and capital gifts to any area of the university. A portion also has been set aside to support smaller gifts. The goal is to compound the long-term growth and impact of the $40 million.</li>
      </ul>

      <h2>What was the process and rationale by which the allocation was determined?</h2>
      <p>President Virjee and his cabinet consulted with multiple campus stakeholders, including staff, faculty, alumni and donors, in deciding how the gift would be used. Decision-makers placed significant focus on Scott’s expressed motivations for the gift:</p>

      <ol>
        <li>Allowing the mission of the campus to continue and expand well into the future, and </li>
        <li>Supporting programs that are uplifting the campus and the communities CSUF serves — programs that drew Scott and her advisers to CSUF. </li>
      </ol>
      
      <p>As such, a noteworthy amount was devoted to a universitywide endowment so future Titans will have the resources needed to continue the transformational work of CSUF. Overlaying Scott’s focus upon the university’s strategic plan helped campus leadership develop a plan for the most effective use of the funds.</p>

      <h2>Why was this allocation of funds chosen?</h2>

      <p>The allocation is aligned with the goals of the university’s strategic plan: </p>
      <ol>
        <li>Providing a transformational Titan experience,</li>
        <li>Supporting student success and degree completion,</li>
        <li>Recruiting and retaining high-quality and diverse employees, and </li>
        <li>Building the campus’ financial and physical capacity. </li>
      </ol>
      
      <p>It balances the institution’s current needs with those of the future, “seeds” additional giving, encourages innovation and recognizes the legacy nature of this extraordinary gift.</p>

      <h2>Are the funds for the matching gift program, president’s strategic initiatives, Academic Affairs, Student Affairs and diversity, equity and inclusion programs  available to be spent now?</h2>
      <p>Taking a strategic approach, some funds are being distributed in the near term for immediate impact and some have been placed in an endowment for future impact.</p>
      
      <h2>What is the process by which the $1 million Student Affairs/Academic Affairs/DEI allocation (for current use) will be determined and by whom?</h2>
      <p>A unique aspect of this gift is that it is unrestricted; it did not come with a directive on how it should be spent. Instead, Scott and Jewett are confident that the university’s mission-driven leadership is in the best position to make that decision. Following this same philosophy, the leadership of the areas that received allocations will make decisions on use of the funds, while providing updates to the cabinet.</p>
      
      <h2>Where can details on the RFP process be found and when will it begin?</h2>
      <p>Details on the RFP process are under development and will be shared widely with the campus community once completed.</p>
      
      <h2>What is the matching gift program?</h2>
      <p>Using $11 million of the gift, the university is offering a 50% match on cash or cash pledge amounts to endowments or capital projects during the 2021-22 fiscal year (ends June 30, 2022). The program aims to double the $11 million value of the gift and build capacity throughout the university.</p>

      <h2>What types of gifts are eligible for the program?</h2>
      <ul>
        <li>Cash gifts and pledges of $35,000 or more made to either a new or existing endowment are eligible for the match. </li>
        <li>Cash gifts and pledges of $200,000 or more made to capital projects are eligible.</li>
      </ul>
 
      <h2>Does the program have any distribution stipulations?</h2>
      <p>No. There is no stipulation that the match funds be allocated across specific campus units. Match dollars will be distributed on a “first-come, first-serve” basis.</p>

      <h2> Are there any caps on the match dollars?</h2>
      <p>Yes. A cap of $1 million can be used as a match for any one gift or pledge. Thus, any eligible gift or pledge of $2 million or more will receive a $1 million match.</p>
      
      <h2>Will the match be available for smaller gifts?</h2>
      <p>Yes, matches have been set up to support gifts of smaller sizes. There will also be opportunities to access the match through #TitansGive, the university’s annual day of giving and other crowdfunding activities.</p>
      
      <h2>When does the program end?</h2>
      <p>It runs now through June 30, 2022.</p>


      </article>
    </section>
    <DOD />
  </Layout>
  </React.Fragment>
);
